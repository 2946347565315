import React, { useContext } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { LanguageContext } from "../../context/languageContext"

const StyledNavigations = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 60px;
  font-size: 16px;
  border-top: 1px solid ${({ theme }) => theme.colors.articleParagraph};
  color: ${({ theme }) => theme.colors.articleParagraph};
  @media ${({ theme }) => theme.mediaQuery.tablet} {
    min-height: 90px;
  }
  a:nth-child(2) {
    text-align: right;
  }
`

const StyledLink = styled(Link)`
  font-family: ${({ theme }) => theme.family.kowaski};
  color: ${({ theme }) => theme.colors.articleExtra};
  font-size: 16px;
  text-decoration: none;
  @media ${({ theme }) => theme.mediaQuery.tablet} {
    width: 60%;
    font-size: 18px;
  }
`

const NavigationPorfolio = ({ nextTo, prevTo }) => {
  const { lang } = useContext(LanguageContext)

  return (
    <StyledNavigations>
      {prevTo && (
        <StyledLink to={lang === "es" ? `/es/${prevTo}` : `/${prevTo}`}>
          {lang === "es" ? "Artículo anterior" : "Previous article "}
        </StyledLink>
      )}
      {nextTo && (
        <StyledLink to={lang === "es" ? `/es/${nextTo}` : `/${nextTo}`}>
          {lang === "es" ? "Artículo siguiente" : "Next article "}
        </StyledLink>
      )}
    </StyledNavigations>
  )
}

export default NavigationPorfolio
