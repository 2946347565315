import React from "react"
import Layout from "./Layout"
import { graphql } from "gatsby"
import styled from "styled-components"
import { format } from "date-fns"
import { NavigationPorfolio } from "../components/NavigationPorfolio"

const StyledPortfolioContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledCover = styled.img`
  display: none;
  border-radius: 5px;
  width: 100%;
  height: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  @media ${({ theme }) => theme.mediaQuery.tablet} {
    display: flex;
  }
`

const StyledCoverMobile = styled.div`
  display: flex;
  border-radius: 5px;
  width: 100%;
  height: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  height: 250px;
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  @media ${({ theme }) => theme.mediaQuery.mobile} {
    height: 300px;
  }
  @media ${({ theme }) => theme.mediaQuery.tablet} {
    display: none;
  }
`

const StyledContainer = styled.div`
  width: 100%;
  margin: auto;
  box-sizing: border-box;
  @media ${({ theme }) => theme.mediaQuery.mobile} {
    width: 90%;
  }
  @media ${({ theme }) => theme.mediaQuery.tablet} {
    width: 60%;
  }
  @media ${({ theme }) => theme.mediaQuery.desktop} {
    width: 55%;
  }
`

const StyledHeader = styled.div`
  width: 100%;
  min-height: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
  @media ${({ theme }) => theme.mediaQuery.desktop} {
    align-items: center;
    flex-direction: row;
  }
`

const StyledTitle = styled.h1`
  font-family: ${({ theme }) => theme.family.kowaski};
  color: ${({ theme }) => theme.colors.articleTitle};
  font-size: 34px;
  @media ${({ theme }) => theme.mediaQuery.tablet} {
    font-size: 32px;
  }
`

const StyledDate = styled.p`
  color: ${({ theme }) => theme.colors.articleParagraph};
  font-size: 14px;
  @media ${({ theme }) => theme.mediaQuery.tablet} {
    font-size: 16px;
  }
`

const StyledContent = styled.div`
  color: ${({ theme }) => theme.colors.articleParagraph};
  font-size: 16px;
  h2 {
    color: ${({ theme }) => theme.colors.primary};
    font-family: ${({ theme }) => theme.family.kowaski};
    margin-bottom: 20px;
    font-size: 16px;
    @media ${({ theme }) => theme.mediaQuery.tablet} {
      font-size: 20px;
    }
  }
  p {
    color: ${({ theme }) => theme.colors.articleParagraph};
    font-size: 16px;
    font-weight: 300;
    line-height: 1.8;
    margin-bottom: 20px;
  }
  a {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;
  }
  img {
    border-radius: 4px;
  }
  ul {
    color: ${({ theme }) => theme.colors.articleParagraph};
  }
`

const LayoutPortfolio = ({ data, pageContext }) => {
  const { prev, next } = pageContext
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  const {
    description,
    imageCoverDesktop,
    imageCoverMobile,
    title_short,
    title_long,
    date,
  } = frontmatter

  const [year, month, day] = date.split("-")

  return (
    <Layout
      metadata={{
        title: title_long,
        description: description,
        cover:
          imageCoverMobile.childImageSharp.gatsbyImageData.images.fallback.src,
        type: "article",
        date: date,
      }}
    >
      <StyledPortfolioContainer>
        <StyledCover
          src={
            imageCoverDesktop.childImageSharp.gatsbyImageData.images.fallback
              .src
          }
          alt={title_short}
        />
        <StyledContainer>
          <StyledCoverMobile
            src={
              imageCoverMobile.childImageSharp.gatsbyImageData.images.fallback
                .src
            }
            alt={title_short}
          />
          <StyledHeader>
            <StyledTitle>{title_short}</StyledTitle>
            <StyledDate>
              Last time updated{" "}
              {format(new Date(year, day, month), "Qo/MM yyyy")}
            </StyledDate>
          </StyledHeader>
          <StyledContent dangerouslySetInnerHTML={{ __html: html }} />
          <NavigationPorfolio nextTo={next} prevTo={prev} />
        </StyledContainer>
      </StyledPortfolioContainer>
    </Layout>
  )
}
export default LayoutPortfolio

export const pageQuery = graphql`
  query SinglePostPortafolio($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        description
        date
        title_short
        title_long
        imageCoverDesktop {
          childImageSharp {
            gatsbyImageData(layout: FIXED)
          }
        }
        imageCoverMobile {
          childImageSharp {
            gatsbyImageData(layout: FIXED)
          }
        }
      }
    }
  }
`
